.btn-home {
  background-color: $color--background;
  color: $color--secondary;
  cursor: pointer;
  border: 1px solid $color--secondary;
  border-radius: 4px;
  padding: 0.5rem 3rem;
  text-align: center;
  display: block;
  width: 100%;
  margin: 0.5em auto;
  text-transform: uppercase;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  @media (min-width: 768px) {
    width: 40%;
  }
  &:hover {
    text-decoration: none;
    color: $color--background;
    background-color: $color--secondary;
  }
}
.btn-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.5rem 0;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}
.btn-group .btn-category {
  border-radius: 2px;
  margin: 0.5rem 0 0 0;
  border: 1px solid $color--secondary;
  background-color: $color--background;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  @media (min-width: 768px) {
    margin: 1rem 0.2rem 1rem 0;
  }
}
.btn-group .btn-category:last-child {
  margin-right: 0;
}
