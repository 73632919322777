.navbar {
  background-color: $color--background;
}

.navbar .navbar-link {
  text-decoration: none;
  color: $color--secondary;
  font-family: $font-heading;
  text-transform: uppercase;
  padding-right: 1em;
  &:hover {
    color: $color--primary;
  }
  &:active {
    text-decoration: underline;
  }
}
.navbar .navbar-link__btn {
  font-family: $font-heading;
}

.admin-nav {
  background-color: $color--primary;
  border-radius: 2px;
  padding: 1rem;
}

.admin-nav .admin-navbar__link {
  background-color: $color--background;
  color: $color--secondary;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.5rem 3rem;
  text-align: center;
  display: block;
  width: 90%;
  margin: 0.5em auto;
  text-transform: uppercase;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
