.info-message,
.form-message {
  display: block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin: 3rem 0;
}

.error-message {
  border: 1px solid $color--error;
}

.validation-error {
  color: $color--error;
  margin-top: 0.4rem;
}
