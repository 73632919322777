$color--primary: #f24405;
$color--secondary: #262626;
$color--background: #f2f2f2;
$color--error: #f41010;

$font-body: "laca", sans-serif;
$font-heading: "kepler-std-caption", sans-serif;

// bootstrap theme colors
$theme-colors: (
  "primary": $color--primary,
  "secondary": $color--secondary,
  "dark": $color--secondary,
);

// colors
$body-bg: $color--background;
$input-bg: #fff;

//typography
$font-family-base: $font-body;
$headings-font-family: $font-heading;
$nav-link-font-size: 1rem;

//cards
