.home-banner {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
  min-height: 500px;
}
.home-banner h1,
.home-banner p {
  padding: 0.2rem 3rem;
  background-color: rgba(38, 38, 38, 0.8);
  border-radius: 2px;
  color: $color--background;
}
