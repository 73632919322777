.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content-wrapper {
  flex: 1;
}
.back-link {
  color: $color--secondary;
  margin-bottom: 2rem;
  text-decoration: none;
}

.details-page {
  margin-top: 3rem;
}
